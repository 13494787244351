const pinch_zoom = {
  max_scale: 3,
  current_scale: 1,
  image_z_index: '999',

  _distance(e) {
    return Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
  },

  _applyTransform(image_el, transform, transition = 'none') {
    image_el.style.transform = transform;
    image_el.style.transition = transition;
  },

  _handleTouchStart(e, image_el) {
    if (e.touches.length === 2) {
      e.preventDefault();
      this.start = {
        x: (e.touches[0].pageX + e.touches[1].pageX) / 2,
        y: (e.touches[0].pageY + e.touches[1].pageY) / 2,
        distance: this._distance(e),
        initial_scale: this.current_scale,
      };
    }
  },

  _handleTouchMove(e, image_el) {
    if (e.touches.length === 2) {
      e.preventDefault();
      const currentX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
      const currentY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
      const deltaX = currentX - this.start.x;
      const deltaY = currentY - this.start.y;

      const delta_distance = this._distance(e);
      const scale = this.start.initial_scale * (delta_distance / this.start.distance);
      this.image_scale = Math.min(Math.max(1, scale), this.max_scale);

      if (this.image_scale > 1 && image_el.style.zIndex !== this.image_z_index) {
        image_el.style.zIndex = this.image_z_index;
      }

      // Include both scale and translation in the transform
      const transform = `translate(${deltaX}px, ${deltaY}px) scale(${this.image_scale})`;
      requestAnimationFrame(() => this._applyTransform(image_el, transform));
    }
  },

  _handleTouchEnd(image_el) {
    const transform = `scale(1)`;
    requestAnimationFrame(() => this._applyTransform(image_el, transform, 'all .2s ease-out'));

    image_el.addEventListener(
      'transitionend',
      () => {
        this.current_scale = 1;
        image_el.style.zIndex = '';
      },
      { once: true }
    );
  },

  addPinchZoomHandler(image_el) {
    image_el.addEventListener('touchstart', (e) => this._handleTouchStart(e, image_el));
    image_el.addEventListener('touchmove', (e) => this._handleTouchMove(e, image_el));
    image_el.addEventListener('touchend', () => this._handleTouchEnd(image_el));
  },
};

export default pinch_zoom;
