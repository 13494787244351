//Movement
// -------------------------------------------------------------------------------------------------------
const movement = {
  _easeInOutQuart(x) {
    if (x < 0.5) {
      return 8 * x * x * x * x;
    } else {
      return 1 - Math.pow(-2 * x + 2, 4) / 2;
    }
  },

  scrollToElementWithEasing(element, duration = 1200, callback) {
    const starting_y = window.scrollY;
    const element_y = window.scrollY + element.getBoundingClientRect().top;
    const target_y =
      document.body.scrollHeight - element_y < window.innerHeight
        ? document.body.scrollHeight - window.innerHeight
        : element_y;
    const diff = target_y - starting_y;

    let start;

    if (!diff) {
      if (typeof callback === 'function') callback();
      return;
    }

    const frameStep = (timestamp) => {
      if (!start) start = timestamp;

      const time = timestamp - start;
      let percent = Math.min(time / duration, 1);
      percent = this._easeInOutQuart(percent);

      window.scrollTo(0, starting_y + diff * percent);

      if (time < duration) {
        window.requestAnimationFrame(frameStep);
      } else {
        if (typeof callback === 'function') callback();
      }
    };

    window.requestAnimationFrame(frameStep);
  },
};

export default movement;
