import projects from './_projects.js';
import movement from './_movement.js';

//Nav Bar
// -------------------------------------------------------------------------------------------------------
const nav_bar = {
  breakpoint: 400,

  ids: {
    title: 'nav-title',
    links: 'nav-links',
    burger_menu: 'nav-burger',
  },

  _getEls() {
    this.title_el = document.getElementById(this.ids.title);
    this.links_el = document.getElementById(this.ids.links);
    this.burger_el = document.getElementById(this.ids.burger_menu);
  },

  createNewLink(text, attrs = { id: null, href: null }) {
    const { id, href } = attrs;

    const anchor_el = document.createElement('a');
    anchor_el.innerText = text;
    if (id) anchor_el.id = id;
    if (href) anchor_el.href = href;

    const item_el = document.createElement('li');
    item_el.appendChild(anchor_el);

    this.links_el.appendChild(item_el);

    return anchor_el;
  },

  addClickListener(anchor_el, fn) {
    anchor_el.addEventListener('click', () => {
      projects.cards.closeAll();
      fn();
    });
  },

  _toggleDisplay(el) {
    const computed = window.getComputedStyle(el);
    const computed_value = computed.getPropertyValue('display');
    const toggle_value = computed_value === 'block' ? 'none' : 'block';

    el.style.display = toggle_value;
  },

  _initBurgerMenu() {
    const burger_el = this.burger_el;
    const toggle_els = [this.links_el, this.title_el];

    burger_el.addEventListener('click', () => {
      toggle_els.forEach((el) => this._toggleDisplay(el));
    });

    let is_above_breakpoint = window.innerWidth > this.breakpoint;

    window.addEventListener('resize', () => {
      const currently_above_breakpoint = window.innerWidth > this.breakpoint;

      if (currently_above_breakpoint !== is_above_breakpoint) {
        is_above_breakpoint = currently_above_breakpoint;

        if (currently_above_breakpoint) {
          toggle_els.forEach((el) => (el.style.display = ''));
        }
      }
    });
  },

  init(thumbnails_section_el) {
    this._getEls();
    this._initBurgerMenu();

    const work_link_el = this.createNewLink('Work', { id: 'work', href: null });
    this.addClickListener(work_link_el, movement.scrollToElementWithEasing.bind(movement, thumbnails_section_el));
  },
};

export default nav_bar;
