import lazy_load from './_lazy_load.js';
import scroll_lock from './_scoll_lock.js';
import url from './_url.js';

// Project Thumbnails / Cards
// -------------------------------------------------------------------------------------------------------
const projects = {
  section_id: 'projects',
  project_attr: 'project',
  map: {},

  classes: {
    active: 'active',
    card_wrapper: 'project-card-wrapper',
    details: 'project-description',
    button_close: 'close-btn',
    button_details: 'description-btn',
    media_container: 'ratio-box',
    media_loading_text: 'loading-text',
  },

  thumbnails: {
    _addProjectProperty(grid_items) {
      for (const item of grid_items) {
        const item_el = item._element;
        const project_val = item_el.getAttribute(projects.project_attr);
        item.project = project_val;
      }
    },

    _createThumbnailMap(thumbnail_items) {
      const project_map = new Map();

      this._addProjectProperty(thumbnail_items);

      thumbnail_items.forEach((item) => {
        const thumb_project_val = item.project;

        const matching_project_cards = projects.card_wrapper_els.filter((el) => {
          const el_project_val = el.getAttribute(projects.project_attr);
          return el_project_val === thumb_project_val;
        });

        if (matching_project_cards.length === 1) {
          const el_pair = { thumb_el: item._element, card_el: matching_project_cards[0] };
          project_map.set(item.project, el_pair);
        }
        // else console.error(`projects: thumbnail match issue for ${item_project_val} -`, matching_thumb_el)
      });

      return project_map;
    },

    _addClickListeners(project_map) {
      const project_entries = project_map.entries();

      for (const [project, el_pair] of project_entries) {
        const { thumb_el, card_el } = el_pair;

        thumb_el.addEventListener('click', () => {
          projects.cards.closeAll();
          url.updateHash(project);
          card_el.open();
        });
      }
    },

    init(thumbnail_items) {
      projects.map = this._createThumbnailMap(thumbnail_items);
      this._addClickListeners(projects.map);
    },
  },

  cards: {
    _initLazyLoad(card_wrapper_el) {
      card_wrapper_el.addEventListener('transitionend',() => {
        lazy_load.init(card_wrapper_el, this._removeLoadingTextClass);
      }, { once: true });
    },

    _addOpenMethod(card_wrapper_el) {
      card_wrapper_el.has_been_opened = false;

      card_wrapper_el.open = () => {
        if (!card_wrapper_el.has_been_opened) {
          card_wrapper_el.has_been_opened = true;
          this._initLazyLoad(card_wrapper_el);
        }

        card_wrapper_el.classList.add(projects.classes.active);
        scroll_lock.disable(card_wrapper_el);
      };
    },

    _addLoadingTextClasses(card_wrapper_el) {
      const media_containers = card_wrapper_el.querySelectorAll(`.${projects.classes.media_container}`);
      const container_els = Array.from(media_containers);

      for (const container_el of container_els) {
        container_el.classList.add(projects.classes.media_loading_text);
      }
    },

    _removeLoadingTextClass(media_el) {
      const media_container = media_el.closest(`.${projects.classes.media_container}`);
      media_container.classList.remove(projects.classes.media_loading_text);
    },

    _addCloseButtonListener(card_wrapper_el) {
      const close_el = card_wrapper_el.querySelector(`.${projects.classes.button_close}`);
      close_el.addEventListener('click', () => {
        url.clearHash();
        card_wrapper_el.classList.remove(projects.classes.active);
        scroll_lock.enable(card_wrapper_el);
      });
    },

    _addDetailsButtonListener(card_wrapper_el) {
      const button_el = card_wrapper_el.querySelector(`.${projects.classes.button_details}`);
      const details_el = card_wrapper_el.querySelector(`.${projects.classes.details}`);

      function updateMaxHeight() {
        const scroll_height = details_el.scrollHeight;
        const max_height_val = `${scroll_height}px`;
        if (details_el.style.maxHeight !== max_height_val) {
          details_el.style.maxHeight = max_height_val;
        }
      }

      button_el.addEventListener('click', () => {
        if (details_el.classList.contains(projects.classes.active)) {
          window.removeEventListener('resize', updateMaxHeight);
          details_el.style.maxHeight = '';
        } else {
          updateMaxHeight();
          window.addEventListener('resize', updateMaxHeight);
        }

        button_el.classList.toggle(projects.classes.active);
        details_el.classList.toggle(projects.classes.active);
      });
    },

    closeAll() {
      for (const card_wrapper_el of projects.card_wrapper_els) {
        if (card_wrapper_el.classList.contains(projects.classes.active)) {
          url.clearHash();
          card_wrapper_el.classList.remove(projects.classes.active);
          scroll_lock.enable(card_wrapper_el);
          return;
        }
      }
    },

    init(card_wrapper_els) {
      for (const card_wrapper_el of card_wrapper_els) {
        this._addOpenMethod(card_wrapper_el);
        this._addLoadingTextClasses(card_wrapper_el);
        this._addCloseButtonListener(card_wrapper_el);
        this._addDetailsButtonListener(card_wrapper_el);
      }
    },
  },

  init(thumbnail_items) {
    const section_el = document.getElementById(this.section_id);
    this.card_wrapper_els = Array.from(section_el.querySelectorAll(`.${this.classes.card_wrapper}`));

    this.thumbnails.init(thumbnail_items);
    this.cards.init(this.card_wrapper_els);
  },
};

export default projects;
