import video from './_video.js';
import image from './_image.js';

// Lazyload
// -------------------------------------------------------------------------------------------------------
const lazy_load = {
  _mediaFunctionCaller(el, media_handlers = { img: null, video: null }) {
    const tag = el.tagName;

    switch (tag) {
      case 'IMG':
        if (typeof media_handlers.img === 'function') media_handlers.img.call(image, el);
        break;
      case 'VIDEO':
        if (typeof media_handlers.video === 'function') media_handlers.video.call(video, el);
        break;
      default:
        break;
    }
  },

  init(card_wrapper_el, onLoaded) {
    const media_handlers = { img: image.addInteractionHandlers, video: video.interactionHandler };

    const instance = new LazyLoad({
      container: card_wrapper_el,
      threshold: 300, //default
      callback_loaded: (el) => {
        onLoaded(el);
        lazy_load._mediaFunctionCaller(el, media_handlers);
      },
      callback_finish: () => instance.destroy(),
    });
  },
};

export default lazy_load;
