import light_box from './_light_box.js';
import pinch_zoom from './_pinch_zoom.js';

// Image
// -------------------------------------------------------------------------------------------------------
const image = {
  addInteractionHandlers(image_el) {
    light_box.addImageClickHandler(image_el);
    pinch_zoom.addPinchZoomHandler(image_el);
  },
};

export default image;
