import projects from './_projects.js';
import nav_bar from './_nav_bar.js';
import movement from './_movement.js';
import three from './_three.js';

//Intro Slider
// -------------------------------------------------------------------------------------------------------
const intro_slider = {
  id: 'intro-slider',
  title_id: 'nav-title',

  map: new Map([
    [
      'intro',
      {
        id: 'greeting',
        anchor_text: null,
      },
    ],
    [
      'about',
      {
        id: 'profile',
        anchor_text: 'Profile',
      },
    ],
  ]),

  _addClickToSlide(anchor_el, slide) {
    anchor_el.addEventListener('click', () => {
      projects.cards.closeAll();
      this.moveToSlide(slide);
    });
  },

  _createAnchor(slide, slide_on_click = true) {
    const text = slide.anchor_text;
    const id = slide.id;

    const attrs = { id: id };
    const anchor_el = nav_bar.createNewLink(text, attrs);

    if (slide_on_click) this._addClickToSlide(anchor_el, slide);

    return anchor_el;
  },

  _initSlides() {
    const map = this.map;
    const slide_keys = map.keys();

    for (const slide_key of slide_keys) {
      const slide = map.get(slide_key);
      slide.el = this.el.querySelector(`#${slide.id}`);
      slide.index = this.slide_els.indexOf(slide.el);
    }

    const title_el = document.getElementById(this.title_id);

    const intro_slide = map.get('intro');
    this._addClickToSlide(title_el, intro_slide);

    const about_slide = map.get('about');
    about_slide.anchor_el = this._createAnchor(about_slide);
  },

  _addTransitionEndHandler() {
    this.el.addEventListener('transitionend', () => {
      three.toggleViewportSceneAnimation();
    });
  },

  moveToSlide(slide) {
    const slider = this.el;

    const base_offset = slider.offsetLeft;
    const top_offset = slider.getBoundingClientRect().top;
    const slide_offset = slide.el.getBoundingClientRect().left - base_offset;

    if (top_offset) {
      movement.scrollToElementWithEasing(slider);
    }

    if (slide_offset) {
      three.startAllSceneAnimation();
      const fraction = -slide.index / (this.length - 1);
      const percent = 100 * fraction;
      this.el.style.transform = `translateX(${percent}%)`;
    }
  },

  init() {
    this.el = document.getElementById(this.id);
    this.slide_els = Array.from(this.el.children);
    this.length = this.slide_els.length;

    this._initSlides();
    this._addTransitionEndHandler();
  },
};

export default intro_slider;
