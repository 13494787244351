/*
-is there a bug when thumbnail click at fullscreen, the bottom row disappears during card transition??
-for videos: media="(min-width: 800px)" etc
-check project container widths: when project as scrollbar vs when it doesnt
*/

import viewport_height from './modules/_viewport_height.js';
import intro_slider from './modules/_intro_slider.js';
import three from './modules/_three.js';
import murri from './modules/_murri.js';
import nav_bar from './modules/_nav_bar.js';
import url from './modules/_url.js';
import projects from './modules/_projects.js';
import light_box from './modules/_light_box.js';

(async function run() {
  const thumbnails_section_el = document.getElementById('projects-thumbnails');
  
  viewport_height.init();
  nav_bar.init(thumbnails_section_el);
  await three.init()
  intro_slider.init();
  light_box.init();
  url.init(thumbnails_section_el);
  murri.init();
  projects.init(murri.grid.items);

  preload.remove()
})();
