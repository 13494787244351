import pinch_zoom from './_pinch_zoom.js';

// Lightbox
// -------------------------------------------------------------------------------------------------------

const light_box = {
  id: 'light-box',
  sizes: '100vw', //this needs to have more sizes?
  hidden_class: 'hidden',
  excluded_classes: ['r-poster'],

  _toggleHiddenClass(container_el) {
    container_el.classList.toggle(this.hidden_class);
  },

  _clearLightBoxImageSrc() {
    this.image.srcset = '';
    this.image.src = '';
  },

  _updateLightBoxImageSrc(image_el) {
    this.image.srcset = image_el.srcset;
    this.image.src = image_el.src;
    this.image.alt = image_el.alt;
  },

  _addLightboxClickListener(container_el) {
    container_el.addEventListener('click', () => this._toggleHiddenClass(container_el));
  },

  _addImageClickListener(source_image_el) {
    source_image_el.addEventListener('click', () => {
      this._onLoad = () => {
        this.image.removeEventListener('load', this._onLoad);
        this.launch();
      };

      this.image.addEventListener('load', this._onLoad);

      this._updateLightBoxImageSrc(source_image_el);
    });
  },

  _testExcludedClasses(source_image_el) {
    const wrapper_el = source_image_el.parentElement;
    const has_excluded_class = this.excluded_classes.some((excluded_class) =>
      wrapper_el.classList.contains(excluded_class)
    );

    return has_excluded_class;
  },

  addImageClickHandler(source_image_el) {
    const has_excluded_class = this._testExcludedClasses(source_image_el);
    if (has_excluded_class) return;

    this._addImageClickListener(source_image_el);
  },

  launch() {
    this._toggleHiddenClass(this.container_el);
  },

  init() {
    this.container_el = document.createElement('div');
    this.container_el.id = this.id;
    this.container_el.classList.add(this.hidden_class);
    this._addLightboxClickListener(this.container_el);

    this.image = document.createElement('img');
    this.image.sizes = this.sizes;
    pinch_zoom.addPinchZoomHandler(this.image);

    this.container_el.appendChild(this.image);
    document.body.prepend(this.container_el);
  },
};

export default light_box;
