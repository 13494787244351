// Thumbnail filter
// -------------------------------------------------------------------------------------------------------
const filter = {
  select: {
    id: 'thumbnails-filter',
    attribute_name: 'categories',

    _filterHandler(muuri_grid, filter_category) {
      const attribute_name = this.attribute_name;

      if (filter_category) {
        muuri_grid.filter((item) => {
          const category_attribute_str = item.getElement().getAttribute(attribute_name);
          const categories_arr = category_attribute_str.split(' ');

          return categories_arr.includes(filter_category);
        });
      } else {
        muuri_grid.show(muuri_grid.items);
      }
    },

    _addChangeListener(muuri_grid) {
      const filter_field_el = document.getElementById(this.id);

      filter_field_el.addEventListener('change', (e) => {
        const category = e.target.value.toLowerCase();
        this._filterHandler(muuri_grid, category);
      });
    },

    init(muuri_grid) {
      this._addChangeListener(muuri_grid);
    },
  },

  random: {
    id: 'filter-randomize',

    shuffleItems(items_arr) {
      const length = items_arr.length;

      for (let i = length - 1; i > 0; i--) {
        const random_index = Math.floor(Math.random() * (i + 1));

        const temp_element = items_arr[i];
        items_arr[i] = items_arr[random_index];
        items_arr[random_index] = temp_element;
      }

      return items_arr;
    },

    randomizeHandler(muuri_grid) {
      const items_arr = muuri_grid.items;
      const shuffled_items_arr = this.shuffleItems(items_arr);

      muuri_grid.refreshSortData(shuffled_items_arr);
      muuri_grid.sort(items_arr);
    },

    _addClickListener(muuri_grid) {
      const randomize_button_el = document.getElementById(this.id);

      randomize_button_el.addEventListener('click', () => this.randomizeHandler(muuri_grid));
    },

    init(muuri_grid) {
      this._addClickListener(muuri_grid);
    },
  },

  init(muuri_grid) {
    this.select.init(muuri_grid);
    this.random.init(muuri_grid);
  },
};

export default filter;
