const viewport_variable = {
  height_var_name: '--vh',

  _isMobileDevice() {
    const userAgent = navigator.userAgent || window.opera;
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobileRegex.test(userAgent);
  },

  _onResize() {
    const vh = window.innerHeight;
    document.documentElement.style.setProperty(this.height_var_name, `${vh}px`);
  },

  _handleViewportHeightVariable() {
    if (this._isMobileDevice()) {
      this._onResize();
      window.addEventListener('resize', this._onResize);
    }
  },

  init() {
    this._handleViewportHeightVariable();
  },
};

export default viewport_variable;
