// Video
// -------------------------------------------------------------------------------------------------------
const video = {
  buffer_text: 'Buffering...',

  classes: {
    buffering: 'buffering',
    ready: 'ready',
    hidden: 'hidden',
    play_button: 'play-btn',
  },

  _togglePlayButton(button_el) {
    button_el.classList.toggle(this.classes.hidden);
  },

  _buffer(video_el, button_el) {
    video_el.classList.add(this.classes.buffering);
    button_el.innerText = this.buffer_text;
    video_el.load();
  },

  _bufferThenFirstPlay(video_el, button_el) {
    const classes = this.classes;

    if (video_el.classList.contains(classes.buffering)) return;

    const play_text = button_el.innerText;
    this._buffer(video_el, button_el);

    function firstPlay() {
      video_el.classList.remove(classes.buffering);
      video_el.classList.add(classes.ready);
      video_el.play();

      button_el.toggle();
      button_el.innerText = play_text;

      video_el.removeEventListener('canplaythrough', firstPlay);
    }

    video_el.addEventListener('canplaythrough', firstPlay);
  },

  _togglePlay(video_el, button_el) {
    video_el.paused ? video_el.play() : video_el.pause();
    button_el.toggle();
  },

  _addClickListeners(video_el, button_el) {
    const video_classes = video_el.classList;
    const classes = this.classes;

    Array(video_el, button_el).forEach((el) => {
      el.addEventListener('click', () => {
        if (!video_classes.contains(classes.ready)) {
          this._bufferThenFirstPlay(video_el, button_el);
          return;
        }

        this._togglePlay(video_el, button_el);
      });
    });
  },

  _addEndedListener(video_el, button_el) {
    video_el.addEventListener('ended', () => {
      video_el.currentTime = 0;
      button_el.toggle();
    });
  },

  interactionHandler(video_el) {
    const parent_el = video_el.parentElement;
    const button_el = parent_el.querySelector(`.${this.classes.play_button}`);

    button_el.toggle = this._togglePlayButton.bind(this, button_el);

    this._addClickListeners(video_el, button_el);
    this._addEndedListener(video_el, button_el);
  },
};

export default video;
