//Three JS
// -------------------------------------------------------------------------------------------------------
const three = {
  parent_ids: {
    hand: 'hand-scene',
    bust: 'bust-scene',
  },

  classes: {
    canvas: 'three-canvas',
    slide: 'slide',
  },

  files: {
    hand_gltf: './three/scenes/Hand_2024_ForWeb-v3(compressed).glb',
    bust_gltf: './three/scenes/Bust_2024_ForWeb(compressed).glb',
    hrd: './three/hdr/hdri_studio_softbox_varA_v001.webp',
  },

  configs: {
    hand: { y_pos: 0.2, aspect_w: 1, camera_fov: 30, camera_dist: 20, start_animated: true },
    bust: { y_pos: 0, aspect_w: 1.3, camera_fov: 15, camera_dist: 45, start_animated: false },
  },

  loaders: {
    gltf: null,
    texture: null,
  },

  scenes: new Map(),

  utilities: {
    degToRad(degrees) {
      return degrees * (Math.PI / 180);
    },

    mapValue(value, from_range, to_range) {
      const [from_low, from_high] = from_range;
      const [to_low, to_high] = to_range;

      return to_low + ((value - from_low) / (from_high - from_low)) * (to_high - to_low);
    },
  },

  initLoaders() {
    const gltf_loader = new window.GLTFLoader();
    const draco_loader = new window.DRACOLoader();
    const texture_loader = new window.THREE.TextureLoader();

    //Draco neccessary if using Draco compression (done via Khronos'site)
    draco_loader.setDecoderPath('./script/draco/');
    gltf_loader.setDRACOLoader(draco_loader);

    this.loaders = {
      texture: texture_loader,
      gltf: gltf_loader,
    };
  },

  initClasses() {
    class SceneBase {
      constructor(parent_el, config = { y_pos: 0, aspect_w: 1.1, camera_fov: 30, camera_dist: 20, scene_rotate: 0 }) {
        this.parent_el = parent_el;
        this.config = config;

        parent_el.style['aspect-ratio'] = `${config.aspect_w} / 1`;
        const { width: parent_width, height: parent_height } = this._getParentSize();

        this.is_in_debug = false;
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(this.config.camera_fov, parent_width / parent_height, 0.1, 1000);
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

        this._setupCamera();
        this._setupRenderer();
        this._setupHDR();
        this._rotateSceneAndCamera(80);
        this._addLights();
        this._setupResizeHandler();

        this.renderer.setSize(parent_width, parent_height);
        this.parent_el.appendChild(this.renderer.domElement);
        this.renderer.domElement.classList.add(three.classes.canvas);

        if (this.is_in_debug) {
          this.orbit_controls = new window.OrbitControls(this.camera, this.renderer.domElement);
          this.parent_el.style.backgroundColor = '#000000';
        }
      }

      _setupCamera() {
        this.camera.position.set(0, 0, this.config.camera_dist);
      }

      _setupRenderer() {
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setClearColor('#FFFFFF', 0); //Background color
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1.3; // Hdri brightness
      }

      _setupHDR() {
        three.loaders.texture //Change Loader for HDR
          // .setDataType(THREE.HalfFloatType) // For HDR
          .load(three.files.hrd, (texture) => {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            this.scene.environment = texture;

            if (this.is_in_debug) {
              // Create environment helper
              const sphere_geometry = new THREE.SphereGeometry(10, 10, 10);

              // THREE.BackSide renders inside
              const sphere_material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide });

              const sphere_mesh = new THREE.Mesh(sphere_geometry, sphere_material);
              // sphere_mesh.rotation.y = three.utilities.degToRad(0);
              this.scene.add(sphere_mesh);
            }
          });
      }

      _rotateSceneAndCamera(deg) {
        const angle = three.utilities.degToRad(deg);

        // Rotate Camera
        this.camera.position.applyAxisAngle(new THREE.Vector3(0, 1, 0), angle);
        this.camera.lookAt(this.scene.position);

        // Rotate all objects and lights in the scene in the opposite direction
        this.scene.traverse((object) => {
          if (object.isMesh) {
            object.rotation.y -= angle;
          }
        });
      }

      _addLights() {
        const directional_light = new THREE.DirectionalLight('#FFFFFF', 1);
        directional_light.position.set(10, 5.5, 2);
        directional_light.castShadow = true; // Doesn't seem to do anything
        directional_light.intensity = 2;
        this.scene.add(directional_light);

        if (this.is_in_debug) {
          // Create directional light helper
          const lightHelper = new THREE.DirectionalLightHelper(directional_light, 5); // The second parameter = size
          this.scene.add(lightHelper);
        }
      }

      _getParentSize() {
        const rect_size = this.parent_el.getBoundingClientRect();
        const { width, height } = rect_size;

        return { width, height };
      }

      _setupResizeHandler() {
        window.addEventListener('resize', () => {
          const { width: parent_width, height: parent_height } = this._getParentSize();
          this.renderer.setSize(parent_width, parent_height);

          this.camera.aspect = parent_width / parent_height;
          this.camera.updateProjectionMatrix();
        });
      }
    }

    class Scene extends SceneBase {
      constructor(source_file, parent_el, config) {
        super(parent_el, config);
        this.source_file = source_file;

        this.mesh = null;
        this.is_animating = config.start_animated;

        this.rotation = {
          limits: { min: -0.03, max: 0.03 },
          default_val: -0.01,
          user_val: null,
          is_default_rotating: true,
        };

        this.zoom = {
          limits: { min: 0.3, max: 2 },
        };
      }

      isWithinViewport() {
        const parentRect = this.parent_el.getBoundingClientRect();
        const withinHorizontalBounds = parentRect.right > 0 && parentRect.left < window.innerWidth;
        const withinVerticalBounds = parentRect.bottom > 0 && parentRect.top < window.innerHeight;
        return withinHorizontalBounds && withinVerticalBounds;
      }

      startAnimation() {
        if (!this.is_animating) {
          this.is_animating = true;
          this._animate();
        }
      }

      stopAnimation() {
        if (this.is_animating) this.is_animating = false;
      }

      async init() {
        const slide_el = this.parent_el.closest(`.${three.classes.slide}`);

        await this._loadModel();
        this._setupMousePassiveHandler(slide_el);
        this._setupMouseControlHandler(slide_el);
        this._animate();
      }

      _animate() {
        if (!this.is_animating) return;

        this.renderer.render(this.scene, this.camera);
        this._defaultMeshRotation();

        if (this.is_in_debug) {
          this.orbit_controls.update();
        }

        requestAnimationFrame(() => this._animate());
      }

      _loadModel() {
        return new Promise((resolve, reject) => {
          three.loaders.gltf.load(
            this.source_file,
            (gltf) => {
              this.mesh = gltf.scene;
              this.mesh.position.set(0, this.config.y_pos, 0);
              this.mesh.scale.set(1, 1, 1);
              this.scene.add(this.mesh);

              if (this.is_in_debug) {
                const axis = new THREE.AxesHelper(4);
                this.mesh.add(axis);
              }

              resolve();
            },
            undefined,
            (error) => {
              console.error('An error occurred loading the GLTF model:', error);
              reject(error);
            }
          );
        });
      }

      _defaultMeshRotation() {
        if (!this.rotation.is_default_rotating) return;
        this.mesh.rotation.y += this.rotation.user_val ?? this.rotation.default_val;
      }

      _setupMousePassiveHandler(element) {
        element.addEventListener('mousemove', (e) => {
          const width = element.offsetWidth;
          const center_x = width / 2;

          let elementRect = element.getBoundingClientRect();
          const mouse_x = e.clientX - elementRect.left;

          const relative_x = mouse_x - center_x;
          const mapped = three.utilities.mapValue(
            relative_x,
            [-center_x, center_x],
            [this.rotation.limits.min, this.rotation.limits.max]
          );

          this.rotation.user_val = mapped;
        });

        element.addEventListener('mouseleave', () => {
          if (this.rotation.user_val) this.rotation.user_val = null;
        });
      }

      _setupMouseControlHandler(element) {
        let is_dragging = false;
        let prev_touch_pos = { x: 0, y: 0 };

        element.style.cursor =  "url('../images/icon-3d_rotate.svg'), all-scroll";

        const getMidpoint = (touch1, touch2) => ({
          x: (touch1.clientX + touch2.clientX) / 2,
          y: (touch1.clientY + touch2.clientY) / 2,
        });

        const onStart = (clientX, clientY) => {
          is_dragging = true;
          this.rotation.is_default_rotating = false;
          prev_touch_pos.x = clientX;
          prev_touch_pos.y = clientY;
        };

        const onMove = (clientX, clientY) => {
          if (is_dragging) {
            const delta_x = clientX - prev_touch_pos.x;
            const delta_y = clientY - prev_touch_pos.y;

            // Rotation
            const rotation_speed = 0.005; // Adjust sensitivity
            this.mesh.rotation.y += delta_x * rotation_speed;
            this.mesh.rotation.z -= delta_y * rotation_speed;

            // Clamp X-axis rotation to prevent flipping
            this.mesh.rotation.z = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, this.mesh.rotation.z));

            prev_touch_pos.x = clientX;
            prev_touch_pos.y = clientY;
          }
        };

        const onEnd = () => {
          is_dragging = false;
          this.rotation.is_default_rotating = true;
        };

        // Mouse events
        element.addEventListener('mousedown', (e) => onStart(e.clientX, e.clientY));
        document.addEventListener('mousemove', (e) => onMove(e.clientX, e.clientY));
        document.addEventListener('mouseup', onEnd);
        element.addEventListener('mouseleave', onEnd);

        // Touch events
        element.addEventListener(
          'touchstart',
          (e) => {
            if (e.touches.length === 2) {
              e.preventDefault();
              const midpoint = getMidpoint(e.touches[0], e.touches[1]);
              onStart(midpoint.x, midpoint.y);
            }
          },
          { passive: false }
        );

        element.addEventListener(
          'touchmove',
          (e) => {
            if (e.touches.length === 2) {
              e.preventDefault();
              const midpoint = getMidpoint(e.touches[0], e.touches[1]);
              onMove(midpoint.x, midpoint.y);
            }
          },
          { passive: false }
        );

        element.addEventListener('touchend', onEnd);
        element.addEventListener('touchcancel', onEnd);
      }
    }

    return { SceneBase, Scene };
  },

  startAllSceneAnimation(){
    this.scenes.forEach(scene => scene.startAnimation())
  },

  toggleViewportSceneAnimation() {
    this.scenes.forEach((scene) => {
      const is_within_viewport = scene.isWithinViewport();
      is_within_viewport ? scene.startAnimation() : scene.stopAnimation();
    });
  },

  setupWindowVisibilityHandler() {
    window.addEventListener('scroll', () => this.toggleViewportSceneAnimation());
  },

  async init() {
    this.initLoaders();

    const { Scene } = this.initClasses();
    this.Scene = Scene;

    const hand_parent_el = document.getElementById(this.parent_ids.hand);
    const hand_scene = new this.Scene(this.files.hand_gltf, hand_parent_el, this.configs.hand);
    this.scenes.set('hand', hand_scene);

    const bust_parent_el = document.getElementById(this.parent_ids.bust);
    const bust_scene = new this.Scene(this.files.bust_gltf, bust_parent_el, this.configs.bust);
    this.scenes.set('bust', bust_scene);

    this.setupWindowVisibilityHandler();

    await hand_scene.init();
    await bust_scene.init();
  },
};

export default three;
