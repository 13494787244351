import filter from './_filter.js';

// Muuri
// -------------------------------------------------------------------------------------------------------
const murri = {
  options: {
    layoutOnInit: true,
    dragEnabled: false,
    containerClass: 'grid',
    itemClass: 'item',
    itemVisibleClass: 'shown',
    itemHiddenClass: 'hidden',
  },

  _randomizedBoolean() {
    return Math.random() >= 0.5 ? true : false;
  },

  _addConstructorOptions(options) {
    options.alignRight = this._randomizedBoolean();
  },

  _preShuffleItems(grid_el, shuffleFn) {
    const item_nodes = grid_el.querySelectorAll(`.${this.options.itemClass}`);
    const items_arr = Array.from(item_nodes);
    const shuffled_arr = shuffleFn(items_arr);

    shuffled_arr.forEach((item) => grid_el.appendChild(item));
  },

  init() {
    this._addConstructorOptions(this.options);

    const grid_el = document.querySelector(`.${this.options.containerClass}`);

    const shuffleFn = filter.random.shuffleItems;
    this._preShuffleItems(grid_el, shuffleFn);

    const grid = new Muuri(grid_el, this.options);
    const items = grid.getItems();

    this.grid = grid;
    this.grid.items = items;

    filter.init(grid);
  },
};

export default murri;
