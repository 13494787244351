import projects from './_projects.js';
import movement from './_movement.js';

//URL
// -------------------------------------------------------------------------------------------------------
const url = {
  _openProjectFromHash(hash) {
    const project_key = hash.substring(1);

    if (projects.map.has(project_key)) {
      projects.cards.closeAll();
      const el_pair = projects.map.get(project_key);
      el_pair.card_el.open();
    }
  },

  getLocationHash() {
    return window.location.hash || false;
  },

  _addPopstateHandler() {
    window.addEventListener('popstate', () => {
      const hash = this.getLocationHash();

      if (hash) {
        this._openProjectFromHash(hash);
      } else {
        projects.cards.closeAll();
        this.clearHash();
      }
    });
  },

  updateHash(hash) {
    hash = `#${hash}`;
    history.pushState(null, null, hash);
  },

  clearHash() {
    if (!window.location.hash) return;
    history.pushState(null, null, window.location.pathname);
  },

  init(thumbnails_section_el) {
    const hash = this.getLocationHash();

    if (hash) {
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }

      movement.scrollToElementWithEasing(thumbnails_section_el, undefined, () => {
        this._openProjectFromHash(hash);
      });
    }

    this._addPopstateHandler();
  },
};

export default url;
